import { Link } from "react-router-dom";
import styles from "../Privacy/Privacy.module.scss";
import logo from "../../image/logo.png";
import Footer from "../Footer/Footer";

function Policy() {
  return (
    <>
      <div className={styles.logo_wrapper}>
        <Link to="/">
          <img src={logo} alt="마이핏노트 로고" />
        </Link>
      </div>
      <div className={styles.text_wrapper}>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제1조 (목적)</p>
          <p className={styles.regular}>
            EXERCOWORK(이하 "회사"라 함)는 이용자의 개인정보를 중요시하며,
            정보통신망법, 개인정보보호법 등 관련 법령을 준수하고 있습니다.
            회사는 개인정보취급방침을 통하여 이용자가 제공한 개인정보가 어떠한
            용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
            취해지고 있는지 알려드립니다.
          </p>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>
            제2조 (수집하는 개인정보의 항목 및 수집방법)
          </p>
          <p className={styles.regular}>
            회사는 다음의 개인정보 항목을 수집할 수 있습니다.
          </p>
          <ul>
            <li>이용자의 식별정보: 아이디(ID), 비밀번호</li>
            <li>운동 데이터: 심박수 데이터, 운동 자세 데이터</li>
          </ul>
          <p className={styles.regular}>
            개인정보는 다음과 같은 방법으로 수집될 수 있습니다.
          </p>
          <ul>
            <li>이용자로부터의 직접 제공</li>
            <li>카메라와 블루투스를 통한 운동 데이터 수집</li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제3조 (개인정보의 수집 및 이용목적)</p>
          <p className={styles.regular}>
            회사는 수집한 개인정보를 다음의 목적을 위해 이용합니다.
          </p>
          <ul>
            <li>서비스 제공 및 관리</li>
            <li> 이용자 식별 및 인증</li>
            <li> 개인맞춤형 서비스 제공</li>
            <li> 서비스 개선 및 신규 서비스 개발</li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제4조 (개인정보의 보유 및 이용기간)</p>
          <p className={styles.regular}>
            회사는 이용자의 개인정보를 수집 및 이용목적 달성 후에는 지체 없이
            파기합니다. 단, 법령에 따라 보존할 필요가 있는 경우 해당 법령에 따라
            보존합니다.
          </p>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제5조 (개인정보의 파기절차 및 방법)</p>
          <p className={styles.regular}>
            회사는 개인정보의 파기를 다음과 같은 절차와 방법으로 진행합니다.
          </p>
          <ul>
            <li>
              파기절차: 개인정보의 수집 및 이용목적이 달성된 후 지체 없이
              파기합니다.
            </li>
            <li> 파기방법: 전자적 방법으로 영구 삭제하거나 파기합니다.</li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제6조 (개인정보의 제3자 제공)</p>
          <p className={styles.regular}>
            회사는 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.
            다만, 이용자의 동의가 있는 경우 또는 법령에 의한 경우에는 예외로
            합니다.
          </p>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제7조 (개인정보의 안전성 확보조치)</p>
          <p className={styles.regular}>
            회사는 개인정보를 안전하게 보호하기 위해 다음과 같은 조치를
            취합니다.
          </p>
          <ul>
            <li> 개인정보 암호화</li>
            <li> 불법적 접근에 대한 보안 시스템 구축 및 운영</li>
            <li> 개인정보 처리 직원의 교육</li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>
            제8조 (이용자 및 법정대리인의 권리와 그 행사방법)
          </p>
          <p className={styles.regular}>
            이용자 및 법정대리인은 개인정보에 대한 열람, 정정, 삭제, 처리정지
            요구 등의 권리를 행사할 수 있습니다. 이에 대한 행사방법은
            개인정보취급방침에 명시되어 있습니다.
          </p>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>
            제9조 (개인정보관리책임자 및 담당자의 연락처)
          </p>
          <p className={styles.regular}>
            회사는 개인정보 보호를 위해 개인정보 관리책임자 및 담당자를 지정하고
            있습니다. 관련 문의사항은 아래의 연락처로 문의하실 수 있습니다.
          </p>
          <ul>
            <li>
              개인정보관리책임자: [관리책임자의 이름, 연락처, 이메일 주소]
            </li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>
            제10조 (개인정보처리방침의 변경에 관한 사항)
          </p>
          <p className={styles.regular}>
            본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 변경
            사항을 공지사항 또는 이메일을 통해 통지합니다.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Policy;
