import styles from "./Page00.module.scss";

function Page00() {
  return (
    <div className={styles.container}>
      <div className={styles.bg_container}>
        <div>
          <p className={`bold ${styles.bold}`}>AI 기반 건강관리시스템</p>
          <p className={`light ${styles.light}`}>
            당신의 건강을 스마트하게 관리하세요.
          </p>
          <hr></hr>
          <p className={`regular ${styles.regular}`}>데일리버전 소개서</p>
        </div>
      </div>
    </div>
  );
}

export default Page00;
