import styles from "./Page04.module.scss";
import logo from "../../image/logo.png";
import img_1 from "../../image/page_4_1.png";
import img_2 from "../../image/page_4_2.png";
import img_3 from "../../image/page_4_3.png";
import img_4 from "../../image/page_4_4.png";

function Page04() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>
          <p className={`bold ${styles.bold}`}>노인신체기능검사항목 상세소개</p>
          <div className={styles.hr}></div>
          <img src={logo} />
        </div>
        <div className={styles.grid_wrapper}>
          <div>
            <img src={img_1} />
            <p className={styles.sub_title}>자세·관절가동범위</p>
            <p className={styles.sub_description}>
              시니어의 불균형한 자세 및 상하지의 관절가동범위를 측정합니다.
            </p>
          </div>
          <div>
            <img src={img_2} />
            <p className={styles.sub_title}>균형기능검사</p>
            <p className={styles.sub_description}>
              외발서기를 통하여 시니어의 균형능력을 측정합니다.
            </p>
          </div>
          <div>
            <img src={img_3} />
            <p className={styles.sub_title}>하지근기능검사</p>
            <p className={styles.sub_description}>
              의자에 앉았다 일어서기를 통하여 시니어의 하지 근력을 측정합니다.
            </p>
          </div>
          <div>
            <img src={img_4} />
            <p className={styles.sub_title}>심폐기능검사</p>
            <p className={styles.sub_description}>
              제자리 걷기를 통하여 시니어의 심폐능력을 측정합니다.
            </p>
          </div>
        </div>
        <p className={styles.text}>
          ※ AI기반의 노인신체기능검사는 전문가 없이도 스스로 검사가 가능합니다.
        </p>
      </div>
    </div>
  );
}

export default Page04;
