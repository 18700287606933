import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.top_wrapper}>
        <Link to="/privacy">개인정보처리방침</Link>
        <Link to="/policy">이용약관</Link>
      </div>
      <p>12902 경기도 하남시 미사강변한강로 155, SKV1 720-1호, 720-2호</p>
      <p>Tel: 02.6338.4500 | Fax: 02.6937.1497</p>
    </div>
  );
}

export default Footer;
