import styles from "./Page03.module.scss";
import logo from "../../image/logo.png";
import icon from "../../image/page_3_1.png";
import img_1 from "../../image/page_3_2.png";
import img_2 from "../../image/page_3_3.png";
import img_3 from "../../image/page_3_4.png";
import img_4 from "../../image/page_3_5.png";

function Page03() {
  return (
    <div className={styles.container}>
      <div className={styles.grid_container}>
        <div className={styles.grid_left}>
          <div className={styles.sub_title}>
            <p>R&D 중요성</p>
            <p>과학적 근거 기반</p>
            <img src={icon} className={styles.icon} />
          </div>
          <div className={styles.textbox}>
            <ul>
              <li>
                <img src={logo} />
                에서
              </li>
              <li>
                <p>
                  사용하고 있는 검사들은 과학적 근거를 기반으로 개발 및
                  검증되었습니다.
                </p>
              </li>
              <li>
                <p>
                  대학병원·스포츠의학·컴퓨터공학·빅데이터·인공지능 등 다양한
                  분야의 박사급 전문가들이 함께 협력하고 있습니다.
                </p>
              </li>
            </ul>
          </div>
          <div className={styles.listbox}>
            <ul>
              <li>자문위원</li>
              <li>아주대학교병원 신경과 문소영교수</li>
              <li>한국스포츠정책과학원 스포츠과학실 송홍선박사</li>
              <li>청주대학교 인공지능소프트웨어학과 노기섭교수</li>
              <li>서경대학교 스포츠앤테크놀로지학과 김알찬교수</li>
            </ul>
          </div>
        </div>
        <div className={styles.grid_right}>
          <div>
            <img src={img_1} />
            <p>알고리즘 일치도 평가 완료</p>
          </div>
          <div>
            <img src={img_2} />
            <p>2023 국립재활원 성능평가</p>
          </div>
          <div>
            <img src={img_3} />
            <p>2023 인지중재치료학회발표</p>
          </div>
          <div>
            <img src={img_4} />
            <p>2019 아주대병원 공동연구</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page03;
