import { useState } from "react";
import Footer from "../Footer/Footer";
import Page00 from "../Page00/Page00";
import Page01 from "../page01/Page01";
import Page02 from "../Page02/Page02";
import Page03 from "../Page03/Page03";
import Page04 from "../Page04/Page04";
import Page05 from "../Page05/Page05";
import Page06 from "../Page06/Page06";
import styled from "styled-components";
import Modal from "../../components/Modal/Modal";

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Banner = styled.div`
  width: 100%;
  text-align: center;
`;

function Main() {

  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = () => setShowModal(false);
  const handleRedirect = () => {
    // Redirect with new tab
    window.open("http://marketian.co.kr:7575/event/leaderboard");

  };

  return (
    <>
     <Banner
     >
        <img src="http://marketian.co.kr:7575/media/myfitnote_banner_0614.jpg" 
             alt="banner"
             height={1000} />
      </Banner>
      <Container>
        <Page00 />
      </Container>
      <Container>
        <Page01 />
      </Container>
      <Page02 />
      <Page03 />
      <Page04 />
      <Page05 />
      <Page06 />
      <Footer />
      <Modal show={showModal} onClose={handleCloseModal} onRedirect={handleRedirect} />
    </>
  );
}

export default Main;
