import styles from "./Page02.module.scss";
import logo from "../../image/logo.png";
import img_1 from "../../image/page_2_1.png";
import img_2 from "../../image/page_2_2.png";
import img_3 from "../../image/page_2_3.png";
import arrow from "../../image/arrow.png";

function Page02() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>
          <p className={`bold ${styles.bold}`}>
            누구나 활용가능한 맞춤형 건강관리시스템
          </p>
          <div className={styles.hr}></div>
          <img src={logo} />
        </div>
        <div className={styles.grid_wrapper}>
          <div>
            <img src={img_1} />
            <p className={styles.sub_title}>노인건강관리</p>
            <img src={arrow} className={styles.arrow} />
            <p className={styles.sub_description}>
              만65세 이상의 노인에게 중요한 관절가동범위, 평형기능, 하지근기능,
              상지근기능, 심폐기능이 포함
            </p>
          </div>
          <div>
            <img src={img_2} />
            <p className={styles.sub_title}>장애인건강관리</p>
            <img src={arrow} className={styles.arrow} />
            <p className={styles.sub_description}>
              발달장애인, 지적장애인, 지체장애인이 측정가능한 유산소, 균형,
              상지기능, 하지기능 등이 포함 상지강화운동·하지강화운동 등
            </p>
          </div>
          <div>
            <img src={img_3} />
            <p className={styles.sub_title}>기본 신체기능관리</p>
            <img src={arrow} className={styles.arrow} />
            <p className={styles.sub_description}>
              기본 신체기능 검사 심폐기능, 근기능, 균형, 보행 등이 포함
              만성질환관리·비만예방 등
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page02;
