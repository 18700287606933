import styles from "./Page05.module.scss";
import qr_code from "../../image/qr.png";
import img_1 from "../../image/page_5_1.png";
import img_2 from "../../image/page_5_2.png";
import img_3 from "../../image/page_5_3.png";
import img_4 from "../../image/page_5_4.png";

function Page05() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title_wrapper}>
          <div></div>
          <div className={styles.text_wrapper}>
            <p>데일리건강관리시스템</p>
            <p>신체기능검사를 통해 맞춤형 운동처방 서비스 제공</p>
          </div>
          <div className={styles.qr_wrapper}>
            <img src={qr_code} />
            <p>소개동영상</p>
          </div>
        </div>
        <div className={styles.grid_wrapper}>
          <div>
            <img src={img_1} />
            <p className={styles.sub_title}>체계적 관리시스템</p>
            <p className={styles.sub_description}>
              건강관리시스템을 통하여 체계적인 관리 및 모니터링이 가능합니다.
            </p>
          </div>
          <div>
            <img src={img_2} />
            <p className={styles.sub_title}>신체기능검사</p>
            <p className={styles.sub_description}>
              정기적인 검사를 통하여 이용자의 건강을 관리합니다.
            </p>
          </div>
          <div>
            <img src={img_3} />
            <p className={styles.sub_title}>맞춤형 운동처방</p>
            <p className={styles.sub_description}>
              의자에 앉았다 일어서기를 통하여 시니어의 하지 근력을 측정합니다.
            </p>
          </div>
          <div>
            <img src={img_4} />
            <p className={styles.sub_title}>모니터링</p>
            <p className={styles.sub_description}>
              지속적인 모니터링을 통해 건강관리를 할 수 있도록 독려합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page05;
