import styles from "./Page01.module.scss";
import logo from "../../image/logo.png";

function Page01() {
  return (
    <div className={styles.bg_container}>
      <div className={styles.top_container}>
        <p className={`bold ${styles.bold}`}>AI기반 건강관리시스템</p>
        <p className={`light ${styles.light}`}>
          하나, 신체기능을 정확하고 편리하게 검사하는 서비스
        </p>
        <p className={`light ${styles.light}`}>
          둘, 개인맞춤형 운동제공으로 건강을 관리하는 서비스
        </p>
        <hr></hr>
        <p className={`bold ${styles.bold}`}>BRAND STORY</p>
      </div>
      <div className={styles.bottom_container}>
        <p className={`regular ${styles.regular}`}>
          심폐기능, 근기능, 균형능력, 보행기능 등 AI기반으로 정확하고 편리하게
          검사하고 <br />
          개인맞춤형 운동제공 및 모니터링을 통한 건강관리시스템
        </p>
      </div>
      <img className="logo" src={logo}></img>
    </div>
  );
}

export default Page01;
