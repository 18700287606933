import { Link } from "react-router-dom";
import styles from "./Privacy.module.scss";
import logo from "../../image/logo.png";
import Footer from "../Footer/Footer";

function Privacy() {
  return (
    <>
      <div className={styles.logo_wrapper}>
        <Link to="/">
          <img src={logo} alt="마이핏노트 로고" />
        </Link>
      </div>
      <div className={styles.text_wrapper}>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제1조 (목적)</p>
          <p className={styles.regular}>
            본 개인정보 처리방침은 EXERCOWORK(이하 "회사"라 함)가 제공하는
            서비스 이용과 관련하여 회사와 회원 간의 개인정보 보호 및 권리 보호에
            관한 사항을 규정함을 목적으로 합니다.
          </p>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제2조 (정의)</p>
          <p className={styles.regular}>
            본 개인정보 처리방침에서 사용하는 용어의 정의는 다음과 같습니다.
          </p>
          <ul>
            <li>"서비스"라 함은 회사가 제공하는 모든 서비스를 의미합니다.</li>
            <li>
              "회원"이라 함은 본 개인정보 처리방침에 동의하고 회사와 이용계약을
              체결한 자를 의미합니다.
            </li>
            <li>
              "아이디(ID)"라 함은 회원의 식별과 서비스 이용을 위하여 회원이
              정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
            </li>
            <li>
              "비밀번호"라 함은 회원의 동일성 확인과 회원정보의 보호를 위하여
              회원 자신이 정한 문자와 숫자의 조합을 의미합니다.
            </li>
            <li>
              "이용계약"이라 함은 서비스 이용과 관련하여 회사와 회원 간에
              체결하는 계약을 의미합니다.
            </li>
            <li>
              "해지"라 함은 회사 또는 회원이 이용계약을 종료시키는 것을
              의미합니다.
            </li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제3조 (개인정보의 수집 및 이용목적)</p>
          <ul>
            <li>
              회사는 다음의 목적을 위하여 회원의 개인정보를 수집 및 이용합니다.
            </li>
            <li className={styles.no_disc}>
              가. 서비스 제공에 따른 계약 이행 및 서비스 제공에 관한 사항 나.
              회원 관리 다. 서비스의 개선 및 신규 서비스 개발 라. 블루투스를
              통한 심박수 데이터 수집 및 운동 상태 분석 마. 카메라를 활용한 운동
              자세 분석 및 개선
            </li>
            <li>
              회사는 회원의 별도 동의를 받은 경우나 법령에 규정된 경우를
              제외하고는 이용자의 개인정보를 해당 목적 범위를 초과하여
              이용하거나 제3자에게 제공하지 않습니다.
            </li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제4조 (개인정보의 보유 및 이용기간)</p>
          <ul>
            <li>
              회사는 회원이 회원자격을 유지하는 동안에 한하여 개인정보를 보유 및
              이용합니다. 다만, 회원이 탈퇴를 요청한 경우 또는 서비스 제공
              목적을 달성한 경우에는 지체 없이 해당 정보를 파기합니다.
            </li>
            <li>
              블루투스를 통한 심박수 데이터 및 카메라를 통한 운동 자세 데이터는
              분석 및 개선을 위한 목적으로 사용되며, 이용자가 명시적으로 삭제를
              요청하지 않는 한 회사의 데이터베이스에 보존됩니다.
            </li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제5조 (개인정보의 파기절차 및 방법)</p>
          <ul>
            <li>
              회사는 회원의 개인정보를 원칙적으로 개인정보의 수집 및 이용목적이
              달성된 후에는 지체 없이 파기합니다. 다만, 다른 법령에 따라
              보존하여야 하는 경우에는 해당 법령에 따라 보존합니다.
            </li>
            <li>
              카메라를 통한 운동 자세 데이터 및 블루투스를 통한 심박수 데이터는
              분석을 위해 일정 기간 보관되며, 분석 목적이 달성된 후에는 복구 및
              파기됩니다.
            </li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제6조 (개인정보의 안전성 확보조치)</p>
          <ul>
            <li>
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
              있습니다.
            </li>
            <li className={styles.no_disc}>
              가. 카메라 및 블루투스를 통한 데이터의 암호화 나. 불법적인 접근 및
              변경, 유출, 손실, 파괴 또는 훼손으로부터 개인정보를 보호하기 위한
              물리적, 기술적, 관리적 조치
            </li>
            <li>
              개인정보처리시스템에 접근 권한을 가진 자는 이용자 본인에 한정되며,
              서비스 제공과 관련된 업무 수행시에만 개인정보에 접근할 수 있도록
              하고 있습니다.
            </li>
          </ul>
        </div>
        <div className={styles.item_wrapper}>
          <p className={styles.big}>제7조 (개인정보의 제3자 제공)</p>
          <ul>
            <li>
              회사는 원칙적으로 회원의 개인정보를 제3자에게 제공하지 않습니다.
              다만, 다음의 경우에는 예외로 합니다.
            </li>
            <li className={styles.no_disc}>
              가. 회원의 동의가 있는 경우 나. 서비스 제공에 따른 계약 이행을
              위하여 필요한 경우 다. 법령에 의해 요구되는 경우
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
