import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./page/Main/Main";
import Policy from "./page/Policy/Policy";
import Privacy from "./page/Privacy/Privacy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/policy" element={<Policy />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
