import styles from "./Page06.module.scss";
import bg from "../../image/bg_4.png";
import qr_code from "../../image/qr_2.png";
import icon_1 from "../../image/icon.png";
import icon_2 from "../../image/icon_2.png";
import logo_1 from "../../image/logo_1.png";
import logo_2 from "../../image/logo_2.png";
import logo_3 from "../../image/logo_3.png";

function Page06() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.kakao_wrapper}>
          <div className={styles.kakao_top}>
            <span>'마이핏노트'</span> <span>채널</span>을 추가해주세요!
          </div>
          <div className={styles.kakao_bottom}>
            <div className={styles.left}>
              <img src={icon_1} className={styles.icon} />
            </div>
            <div className={styles.middle}>
              <div className={styles.middle_top}>
                무료체험서비스관련 1:1 문의 대환영!
              </div>
              <div className={styles.middle_bottom}>
                <img src={icon_2} />
                <p>070-7954-9848</p>
              </div>
            </div>
            <div className={styles.right}>
              <img src={qr_code} className={styles.icon} />
              <p>카카오톡 채널추가QR</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.middle}>
        <p>본 서비스는 다양한 기업과 협력하고 있습니다.</p>
        <div className={styles.logo_wrapper}>
          <img src={logo_1} />
          <img src={logo_2} />
          <img src={logo_3} />
        </div>
      </div>
      <div className={styles.bottom}>
        <img src={bg} />
      </div>
    </div>
  );
}

export default Page06;
